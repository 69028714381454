import React from "react"
import { useTranslation } from "react-i18next"
import { Container, Typography, Box } from "@material-ui/core"
import "../styles/general.css"
import Footer from "./Footer"
import StudiesButtonsComponent from "./StudiesButtonsComponent"
import StudiesFormalComponent from "./StudiesFormalComponent"

const StudiesComponent = () => {
  const { t, i18n } = useTranslation()
  return (
    <div className="componentsDiv">
      <Box align="justify">
        <h1 className="titlesGradientH1">{t("title.studies")}</h1>
        <StudiesButtonsComponent />
        <StudiesFormalComponent />
      </Box>
      <br />
      <Box>
        <Footer />
      </Box>
    </div>
  )
}

export default StudiesComponent
