import React from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Box, Divider, Paper } from "@material-ui/core"
import "../styles/general.css"

import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Collapse from "@material-ui/core/Collapse"
import Avatar from "@material-ui/core/Avatar"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { red } from "@material-ui/core/colors"
import FavoriteIcon from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import MoreVertIcon from "@material-ui/icons/MoreVert"

import ImgUnisabanaH from "../components/imgComponents/imgUnisabanaH"
import ImgHospitalKennedy from "./imgComponents/imgHospitalKennedy"
import ImgHospitalTunal from "./imgComponents/imgHospitalTunal"
import ImgHospitalSanBlas from "./imgComponents/imgHospitalSanBlas"
import ImgHospitalSanRafaelFaca from "./imgComponents/imgHospitalSanRafaelFaca"
import ImgHospitalSamaritanaZipa from "./imgComponents/imgHospitalSamaritanaZipa"
import ImgHospitalTunjuelito from "./imgComponents/imgHospitalTunjuelito"
import ImgSecretariaSalud from "./imgComponents/imgSecretariaSalud"
import ImgHospitalSanRafaelFusa from "./imgComponents/imgHospitalSanRafaelFusa"
import ImgClinicaShaio from "./imgComponents/imgClinicaShaio"
import ImgInc from "./imgComponents/imgInc"
import ImgClinicaLaSabana from "./imgComponents/imgClinicaLaSabana"
import ImgAmiPallium from "./imgComponents/imgAmiPallium"
import ImgBienestarIps from "./imgComponents/imgBienestarIps"
import ImgCardenalSanchaSq from "./imgComponents/imgCardenalSanchaSq"
import ImgUnisanmartinSq from "./imgComponents/imgUnisanmartinSq"
import ImgUnisabanaSq from "./imgComponents/imgUnisabanaSq"

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

const StudiesFormalComponent = () => {
  const { t, i18n } = useTranslation()

  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Box mt={1}></Box>
      <h2 className="titlesGradientH2">{t("studies.formaltitle")}</h2>
      <Divider />
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgUnisabanaSq />}
          title={t("university.lasabana")}
          subheader="Chía - Colombia, 2020 - 2022"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("profile.palliativecareresidency")}
            <br />
            {t("profile.palliativecareresidency.year")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgUnisanmartinSq />}
          title={t("university.sanmartin")}
          subheader="Bogotá - Colombia, 2012 - 2017"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.unisanmartin.degree")}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card className={classes.root} variant="outlined" square>
        <CardHeader
          avatar={<ImgCardenalSanchaSq />}
          title={t("school.cardenalsancha")}
          subheader="Bogotá - Colombia"
        />
        <Divider />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {t("studies.cardenalsancha.degree")}
          </Typography>
        </CardContent>
      </Card>
      <br />
    </div>
  )
}

export default StudiesFormalComponent
