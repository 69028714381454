import React from "react"
import { useTranslation } from "react-i18next"
import { Button } from "@material-ui/core"
import "../styles/general.css"
import { Link } from "gatsby"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(0.5),
  },
}))

const StudiesButtonsComponent = () => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  return (
    <div>
      <Button
        size="small"
        color="primary"
        className={classes.margin}
        style={{ textTransform: "none" }}
        component={Link}
        to="/studies"
      >
        {t("studies.formal")}
      </Button>
      <Button
        size="small"
        color="primary"
        className={classes.margin}
        style={{ textTransform: "none" }}
        component={Link}
        to="/complementary"
      >
        {t("studies.complementary")}
      </Button>
    </div>
  )
}

export default StudiesButtonsComponent
